import React from 'react';
import MenuItem from './MenuItem';
import './Menuarea.css';

const Menuarea = (props) => {
    return(
            <div className="Menuarea">
                {props.itemlist.map((menuitem,index)=>{
                    return(
                        <MenuItem key={index} menuitem={menuitem}/>
                        )
                })}
            </div>
        )
}

export default Menuarea;