import React from 'react';
// import { Toast } from 'react-bootstrap';
import './MenuItem.css'

const MenuItem = (props) => {

    return(
        <div className="MenuItem">
            <div className="MenuItemHeader">
                <span className="ItemName">{props.menuitem.name}</span>
                <span className="Price">{props.menuitem.price} / {props.menuitem.unit}</span>
            </div>
            {/* <div className="MenuItemFooter">
                <span>{props.menuitem.description}</span>
            </div> */}
            {/* <Toast>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">{props.menuitem.name}</strong>
                    <small>11 mins ago</small>
                </Toast.Header>
            <Toast.Body>{props.menuitem.description}</Toast.Body>
            </Toast> */}
            
        </div>
    )

}

export default MenuItem;