import React from 'react';
import './CarousalCo.css';
import { Carousel } from 'react-bootstrap';

const CarousalCo = (props) => {
    return(
        <div className="CarouslCo">
        <Carousel>
                
                    <Carousel.Item>
                    <div className="CarouselImage1"></div>
                    {/* <img
                    className="d-block w-100 cimage"
                    // src="https://i.ibb.co/hfjGfMk/meals.jpg"
                    src={onepng}
                    alt="First slide"
                    /> */}
                <Carousel.Caption>
                        {/* <p className="Caption">CrazzyBites</p> */}
                        {/* <p>{props.images[0].description}</p> */}
                </Carousel.Caption>
            </Carousel.Item>           
            <Carousel.Item>
            <div className="CarouselImage2"></div>        
            <Carousel.Caption>
                        
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <div className="CarouselImage3"></div>
        
        <Carousel.Caption>
                    
            </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
            <div className="CarouselImage4"></div>
        
        <Carousel.Caption>
                        
            </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        </div>
        )
    }
    
    export default CarousalCo;