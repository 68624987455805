import { React, useState, useEffect } from 'react'
import './MenuSection.css'
import { Nav } from 'react-bootstrap'
import Menuarea from './Menuarea'

const MenuScction = (props) => {

    const [itemList, setItemList] = useState(props.menuCategory[0]['items'])

    useEffect(()=>{
        setItemList(props.menuCategory[0]['items'])
    },[props.menuCategory])


    const subcategorySelected = (itemlist) => {
        setItemList(itemlist)
        // console.log(itemlist)
    }
    return(
        <div className="MenuSection">
            <Nav fill variant="tabs" defaultActiveKey="0">
                {props.menuCategory.map((menuCategoryItem,index) => {
                    return(
                        <Nav.Item>
                            <Nav.Link  key={index} 
                                        eventKey={index.toString()} 
                                        onClick={(e) => subcategorySelected(menuCategoryItem['items']) }>
                                            <div className="NavLinkOR" key={index}>{menuCategoryItem['subcategory']}</div>
                            </Nav.Link>
                        </Nav.Item>
                        )
                })}
            </Nav>
            <Menuarea itemlist={itemList}/>
        </div>
        )
}

export default MenuScction;