// import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import Nav1 from './components/head/Nav1';
import FullMenu from './components/page/FullMenu';
// import { useState } from 'react';

function App() {

  const [meta,setMeta] = useState({})
  const [loadingMeta,setLoadingMeta] = useState(true)

  // const [page, setPage] = useState('Active')
  useEffect(()=>{
    fetch("https://crazzybites-79a2e-default-rtdb.asia-southeast1.firebasedatabase.app/meta.json")
    .then(response =>response.json())
    .then(result => {
        setMeta(result)
        setLoadingMeta(false)})
    .catch(error => console.log('Error fetching meta data from crazzybites db'))
  },[])
  
  return (
    <>
    {loadingMeta && <><div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></>}
    {!loadingMeta && <Nav1 contact={meta.contact} whatsapp={meta.whatsapp}/>}
    {!loadingMeta && <FullMenu images={meta.images}/>}
    </>
  );
}

export default App;
