import React from 'react'
import './Sidetabs.css'

const Sidetabs = (props) => {

    const sideTabSelected = (tabListItem) => {
        props.tabListItemSelected(tabListItem)
    } 

    return(
        <div className="Sidetabs">
                {props.tabsList.map((tabListItem,index) => {
                    return(
                        <div key={index} className="TabListItem" onClick={e => sideTabSelected(tabListItem)}>{tabListItem}</div>)
                })}
        </div>
        )
}

export default Sidetabs;