import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import './Nav1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePhone } from '@fortawesome/free-solid-svg-icons'
import whatsappicon from '../../../src/images/logo-wa.png';


const Nav1 = (props) => {
    return(
        <Navbar className="NavBGcol">
            <Container>
                <Navbar.Brand ><div className="logoStyle">Crazzy Bites</div></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <img src="https://upload.wikimedia.org/wikipedia/en/e/e2/FSSAI_logo.png" alt="fssai" className='Fssai'/>
                    <a href={props.contact} className="Icons"><FontAwesomeIcon icon={faSquarePhone}/></a>
                    <a href={props.whatsapp} rel="noreferrer" target="_blank"><img src={whatsappicon} alt="Whatsapp" class="Whatsapp"/></a>
                {/* <Navbar.Text>
                    Signed in as: <a href="#login">Mark Otto</a>
                </Navbar.Text> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
        )
    }
    
export default Nav1;