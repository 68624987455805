import React, { useState } from 'react';
import CarousalCo from './CarousalCo'
import Sidetabs from './Sidetabs'
import MenuSection from './MenuSection'
import './FullMenu.css'
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap'

const FullMenu = (props) => {

    const [loading,setLoading] = useState(true)
    const [sideTabsList, setSideTabsList] = useState([])
    const [fullMenu, setFullMenu] = useState({})
    const [menuCategorySelected, setMenuCategorySelected] = useState([])

    useEffect(() => {
        fetch("https://crazzybites-79a2e-default-rtdb.asia-southeast1.firebasedatabase.app/foodcatagories.json")
        .then(resp => resp.json())
        .then((res) => setSideTabsList(res))
        .catch(e => {})

        fetch("https://crazzybites-79a2e-default-rtdb.asia-southeast1.firebasedatabase.app/fooddatabase.json")
        .then(resp => resp.json())
        .then(result => {
            setFullMenu(result)
            setMenuCategorySelected(result['Snacks'])
            setLoading(false)    
        })
        .catch(e => {})
    },[])

    

    const tabListItemSelected = (tabListItem) =>{
        setMenuCategorySelected(fullMenu[tabListItem])
        
    }

    let content = (
        <div className="Spin"><Spinner animation="border" variant="danger" />
        <Spinner animation="border" variant="danger" />
        <Spinner animation="border" variant="danger" />
        <Spinner animation="border" variant="danger" />
        <Spinner animation="border" variant="danger" />
        </div>
    )
    if(!loading){
        content=(
            <div className="MainMenu">
                    <Sidetabs tabsList={sideTabsList} tabListItemSelected={tabListItemSelected}/>
                    <MenuSection menuCategory={menuCategorySelected}/>
                </div>
        )
    }

    return(
            <div className="FullMenu">
                <div className="CarouelContainer">
                    <div className="Booking">
                        Please make prior booking with us - call or whatsapp for inquiry
                    </div>
                    <div className="Carousal">
                        <CarousalCo images={props.images}/>
                    </div>
                </div>
                {content}
            </div>
        )
}

export default FullMenu;